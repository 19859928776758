.social {
  .icon {
    margin-right: 1em;

    &:hover {
      fill: #E2B23C;
    }

    svg {
      height: 3em;
      width: 3em;
    }
  }
}
@import url('https://fonts.googleapis.com/css2?family=Alegreya&display=swap');

@font-face {
  font-family: 'Alegreya-Black';
}

.home {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows:50vh;
  min-width: 320px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .content {
    h1 {
      font-size: 2em;
      color: #E2B23C;
    }

    p {
      font-size: 18px;

      &:last-of-type {
        margin-bottom: 2em;
      }
    }

    hr {
      width: 100%;
      margin: 0;
      border-top: 1px solid #E2B23C;
    }

    padding: 40px 60px;
    align-self: center;
  }
}

@media (min-width: 992px) {
  .home
  {
     grid-template-columns: repeat(2, minmax(0, 1fr));
     grid-template-rows: 100vh;

     .content {
       h1 {
        font-size: 3em;
       }
       hr {
         width: 50%;
       }
     }
  }
}

.Typist .Cursor {
  display: inline-block; 
}
  .Typist .Cursor--blinking {
    opacity: 1;
    animation: blink 1s linear infinite; 
  }

@keyframes blink {
  0% {
    opacity: 1; 
  }
  50% {
    opacity: 0; 
  }
  100% {
    opacity: 1; 
  } 
}
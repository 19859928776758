@font-face {
  font-family: 'Roboto';
  font-weight: normal;
  font-style: normal;
  src: url(../assets/fonts/Roboto-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: normal;
  font-style: italic;
  src: url(../assets/fonts/Roboto-Italic.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: bold;
  font-style: normal;
  src: url(../assets/fonts/Roboto-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: bold;
  font-style: italic;
  src: url(../assets/fonts/Roboto-BoldItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: lighter;
  font-style: normal;
  src: url(../assets/fonts/Roboto-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: lighter;
  font-style: italic;
  src: url(../assets/fonts/Roboto-LightItalic.ttf) format('truetype');
}

body {
  font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}